.eyeoff {
    width: 17px;
    position: absolute;
    top: 32%;
    left: 86%;
}

.connectorPassword{
    margin-right:1rem;
}

.font-3{
    font-size: 3em !important;
}

.hidden{
    display: none;
}

.tile-sub{
    font-size: 1.5em;
    position: relative;
    top: 16%;
    line-height: 1.5;
    font-weight: bold;
}

.tile-main{
    font-family: revert;
}

.font-2-7{
    font-size: 2.7em !important;
}

.text-center{
    text-align: center;
}

.pos-right-10{
    position: relative;
    right: 10px;
}

.tile-1{
    background-color: #4294FF;
}

.tile-2{
    background-color: #39D779;
}

.tile-3{
    background-color: #96C93D;
}

.tile-4{
    background-color: #022060;
}

.with-img-tile .tile-sub{
    top:0%;
}

.w-150{
    width: 150px;
}
.w-200{
    width: 200px;
}
.w-250{
    width: 250px;
}

.w-400{
    width: 400px;
}

.w-350{
    
    width: 350px;
}

.h-355{
    height: 355px;
}

.pointer{
    cursor: pointer;
}

.menu-icon{
    width: 25px;
    position: relative !important;
    left: 3px !important;
    margin-right: 10px;
}

.collapsed-logo{
    display: none;
}


.menu-collapsed .main-logo{
    display: none !important; 
}

.menu-collapsed .collapsed-logo{
    display: block;
    width: 59px;
    position: relative;
    top: -16px;
    right: 12px;
}

.main-logo{
    width: 180px;
    /* display: hidden; */
}

.elie-icon{
    width: 25px;
    position: relative !important;
    /* left: 3px !important */
    margin-right: 10px;

    zoom: 1.3;
    position: relative;
    left:-5px;
}

.elie-icon + span{
    position: relative;
    left: -14px;
}

.col-green{
    color: #99c93c;
}

.home-tile-section{
    margin: 40px;
}

.home-tile:hover{
    box-shadow: 0 10px 15px rgba(12,10,11,0.3);
    transition: box-shadow 0.3s ease-in-out;
    cursor: pointer;
}

.text-right{
    text-align: right;
}

.text-left{
    text-align: left;
}

.zoom-0-9{
    zoom: 0.9;
}

.vertical-layout .main-menu.theme-primary .navigation .nav-item.active a {
    background:  #040474;
}

.menu-content .active{
    background-color: #040474 !important;
}

.pie-chart .card{
    height:480px;
}

.menu-text svg{
    color: #a7c715;
}

.login-logo{
    zoom: 1.3;
}